.container2 {
	background-image: url(../components/images/ciudadpanorama.jpg);
	background-size: 100%;
	/* background-repeat: no-repeat; */
	display: flex;
	flex-direction: column;
	margin-right: 15px;
	padding: 15px;
	width: 100%;
	height: 450px;
}

.login {
	margin-top: 30px;
	margin-bottom: 250px;
	padding-left: 10%;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	width: 50%;
	height: 100%;
}

.fondo form {
	width: 100%;
	background-color: rgba(31, 141, 166, 0.5);
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.text-sinpermiso {
	margin-top: 50px;
	margin-left: 20px;
	padding: 10px;
	background-color: red;
	font-size: 20px;
	color: white;
	border-radius: 20px;
}

.titleLogin {
	color: rgb(203, 215, 233);
	font-size: 40px;
}
