.container2 {
	/* margin-top: 100px; */
	background-image: url(../components/images/ciudadpanorama.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	margin-right: 15px;
	padding: 15px;
	width: 100%;
	height: 1000px;
	/* background-color: rgb(6, 54, 110); */
}

.login {
	margin-top: 30px;
	margin-bottom: 250px;
	padding-left: 10%;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	width: 50%;
	height: 100%;
}

.fondo form {
	width: 100%;
	background-color: rgba(31, 141, 166, 0.5);
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	height: 300px;
	border-radius: 20px;
}

.text-sinpermiso {
	margin-top: 50px;
	margin-left: 20px;
	padding: 10px;
	background-color: red;
	font-size: 20px;
	color: white;
	border-radius: 20px;
}

.registerTitle {
	color: #fff;
	font-size: 30px;
}

.inputFormat {
	margin-top: 10px;
	margin-bottom: 10px;
	height: 30px;
	border-radius: 10px;
	width: 200px;
}

.textError {
	color: crimson;
	background-color: rgb(174, 192, 252);
	border-radius: 10px;
	margin-left: 10px;
}

.registerButton {
	margin-top: 25px;
	background-color: rgb(71, 36, 201);
	color: rgb(231, 214, 231);
	height: 20px;
}

.labelEmpresa {
	color: #fff;
	text-align: left;
	text-justify: kas;
}
